<template>
    <!-- Comments-->
    <section aria-labelledby="notes-title">

        <div class="text-left">
            <div class="divide-y divide-gray-200">
                <div class="px-4 py-6 sm:px-6">
                    <div class="flow-root">
                        <div class="mb-5">Order Activity</div>

                        <div v-if="addCommentAction" class="pt-5">
                            <div class="flex space-x-3">
                                <div class="flex-shrink-0">
                                    <profile-image class="flex justify-center items-center" :initials="initials(currentUserName)" :profileImage="currentUserProfile" :ossImage="currentUserOssImage"></profile-image>
                                </div>
                                <div class="min-w-0 flex-1">
                                    <form action="#">
                                        <div>
                                            <label for="comment" class="sr-only">Comment</label>
                                            <textarea id="comment" name="comment" rows="3"
                                                      class="p-1 bg-v3-gray-900 bg-opacity-5 text-v3-gray-900 text-opacity-70 placeholder-v3-gray-900 placeholder-opacity-70 dark:bg-white dark:bg-opacity-5 dark:text-white dark:text-opacity-70 dark:placeholder-white dark:placeholder-opacity-70 form-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-0 rounded-md"
                                                      placeholder="Add a comment" v-model="comment"></textarea>
                                            <div v-show="errors.has('comment')" class="text-v3-red text-sm">{{ errors.first('comment') }}</div>

                                        </div>
                                        <div class="mt-3 flex items-center justify-end">
                                            <custom-button @click.native="performAddComment" colourType="tertiary" :loading="submitting">Comment</custom-button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <ul role="list" class="-mb-8">
                            <div v-for="(activity, index) in activities">
                                <li>
                                    <div class="relative pb-8">
                                        <span class="absolute top-5 left-7 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" v-if="index + 1 !== activities.count()"></span>
                                        <div class="relative flex items-start space-x-3">
                                            <div>
                                                <div class="relative px-1">
                                                    <profile-image :initials="initials(activity.entity.properties.get('causer'))" :profile-image="activity.entity.properties.get('causer_profile_image')"></profile-image>
                                                    <!--
<template v-if="activity.entity.properties.get('activity').startsWith('Comment')">-->
<!--                                                        <profile-image :initials="initials(activity.entity.properties.get('causer'))"></profile-image>-->
<!--&lt;!&ndash;                                                        <span class="absolute -bottom-0.5 -right-1 rounded-tl px-0.5 py-px">&ndash;&gt;-->
<!--&lt;!&ndash;                                                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">&ndash;&gt;-->
<!--&lt;!&ndash;                                                              <path stroke-linecap="round" stroke-linejoin="round" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />&ndash;&gt;-->
<!--&lt;!&ndash;                                                          </svg>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </span>&ndash;&gt;-->
<!--                                                    </template>-->
<!--                                                    <div v-else >-->
<!--                                                        <profile-image :initials="initials(activity.entity.properties.get('causer'))"></profile-image>-->

<!--                                                    </div>-->


                                                </div>
                                            </div>
                                            <div class="min-w-0 flex-1 py-1.5">
                                                <div v-if="!activity.entity.properties.get('activity').startsWith('Comment')" class="text-sm text-gray-500 dark:text-white">
                                                    <a href="#" class="font-medium text-gray-900 dark:text-v3-base-200">{{ activity.entity.properties.get('causer') }}</a>
                                                    {{activity.entity.properties.get('activity')}}
                                                    <span class="whitespace-nowrap">{{getNumberOfDaysElapsed(activity.entity.properties.get('date'))}} days ago</span>
                                                </div>
                                                <template v-else>
                                                    <div>
                                                        <div class="text-sm">
                                                            <a href="#" class="font-medium text-gray-900 dark:text-v3-base-200">{{ activity.entity.properties.get('causer') }}</a>
                                                        </div>
                                                        <p class="mt-0.5 text-sm text-gray-500 dark:text-v3-base-200 dark:text-opacity-90">Commented {{getNumberOfDaysElapsed(activity.entity.properties.get('date'))}} days ago</p>
                                                    </div>
                                                    <div class="mt-2 text-sm dark:text-v3-base-200 dark:text-opacity-70">
                                                        <p>{{activity.entity.properties.get('activity').replace('Comment - ', '')}}</p>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </div>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
import fileDownload from "js-file-download";
import Siren from "super-siren";
import {mapGetters} from "vuex";
import CustomButton from "@/v3/Buttons/CustomButton.vue";
import ProfileImage from "@/v3/components/ProfileImage.vue";
export default {
    name: "ActivityFeed",
    data(){
        return {
            comment: null,
            submitting: false,
            currentUserName: null,
            currentUserProfile: null,
            currentUserOssImage: null
        }
    },
    components: {
        CustomButton,
        ProfileImage
    },
    props:{
        entity: {
            type: Object
        },
    },
    computed: {
        activities(){
            return this.entity.entities.filter(function(entity){
                let activity = null;
                if(entity.rels.contains('activities')) {
                    activity = entity.entity.properties.get('activity');
                }else{
                    return entity.rels.contains('activities');
                }
                return entity.rels.contains('activities') &&
                    activity !== 'Added an order item' &&
                    !activity.includes('Quote request sent to') &&
                    activity !== 'Updated an order item' &&
                    activity !== 'Marked as quotes received' &&
                    activity !== 'Update Requested' &&
                    activity !== 'Quote Updated' &&
                    activity !== 'Removed an order item' &&
                    activity !== 'Invoice Added' &&
                    !activity.includes('uploaded to sage') &&
                    activity !=='Partial Delivery Event Added';
            });
        },
        addCommentAction(){
            return this.entity.actions.filter(action => action.name === 'add-comment').first();
        },
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser",
            message: "getMessage",
            messageWithoutTimeout: "getMessageWithoutTimeout"
        }),
    },
    methods:{
        getNumberOfDaysElapsed(fromDate){
            let splitFromDate1 = fromDate.split(' ');
            let splitFromDate2 = splitFromDate1[1].split('/')
            let rejoinedDate = splitFromDate2[1] + '/' + splitFromDate2[0] + '/' + splitFromDate2[2] + ' ' + splitFromDate1[0];
            let date_1 = new Date(rejoinedDate);
            let date_2 = new Date();

            let difference = date_1.getTime() - date_2.getTime();
            let TotalDays = Math.ceil(difference / (1000 * 3600 * 24)) * -1;
            return TotalDays;
        },
        performAddComment() {
            this.submitting = true;
            this.addCommentAction.perform({'comment': this.comment}).then(res => {
                this.submitting = false;
                this.$store.dispatch(
                    "setMessage",
                    this.messages(res).entity.properties.get("success")
                );
                this.$emit('success', res);
            }).catch(error => {
                this.submitting = false;
                if(error.status === 422) {
                    this.$setLaravelValidationErrorsFromResponse(
                        JSON.parse(error.response.text)
                    );
                }else{
                    alert('There was an error processing your request.' + error);
                }
                this.$emit('failure');
            });
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        },
        initials(userName) {
            if (userName) {
                return userName
                    .replace(/[^a-zA-Z- ]/g, "")
                    .match(/\b\w/g)
                    .join("");
            }
        }
    },
    mounted(){
        console.log(this.user['id']);

        this.currentUserProfile = this.user['profile_image'];
        this.currentUserName = this.user['name'];

        this.$forceUpdate();
    }
}
</script>

<style scoped>

</style>
